<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M8 1H14C19 1 21 3 21 8V14C21 19 19 21 14 21H8C3 21 1 19 1 14V8C1 3 3 1 8 1Z" stroke-width="2"
      stroke-linecap="round" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'" d="M7.57007 14.27L14.11 7.72998" stroke-width="1.5"
      stroke-linecap="round" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M7.98013 9.36966C8.65944 9.36966 9.21012 8.81899 9.21012 8.13968C9.21012 7.46037 8.65944 6.90967 7.98013 6.90967C7.30082 6.90967 6.75012 7.46037 6.75012 8.13968C6.75012 8.81899 7.30082 9.36966 7.98013 9.36966Z"
      stroke-linecap="round" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M14.52 15.0899C15.1993 15.0899 15.75 14.5392 15.75 13.8599C15.75 13.1806 15.1993 12.6299 14.52 12.6299C13.8407 12.6299 13.29 13.1806 13.29 13.8599C13.29 14.5392 13.8407 15.0899 14.52 15.0899Z"
      stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PlansIcon',
  props: {
    isActive: Boolean,
  },
})
</script>


