<template>
  <div class="">
    <div class="header-border header">
      <h1 class="text-title text-bold">Lista de clientes</h1>
    </div>
    <router-link to="/cliente/criar-pj">
      <button class="button-add text-semibold">Criar Cliente PJ</button>
    </router-link>
    <div class="customer-search">
      <form>
        <fieldset>
          <input class="customer-input text-search text-italic text-gray" type="text" name="" id=""
            placeholder="buscar..." v-model="param" @keyup.enter="getCustomers">
          <img src="../assets/img/lupa.svg" alt="" @click="getCustomers">
        </fieldset>
      </form>
    </div>
    <CustomerTable :columns="columns" :rows="rows" v-if="!loading" />
    <LoadingComponent :columns="columns" v-if="loading" />
    <div v-if="!loading" class="customer-footer">
      <div class="">
        <p class="text-footer text-regular">Exibindo de {{ fromCount }} a {{ toShownCount }} de {{ totalItems }} itens</p>
      </div>
      <div class="">
        <button class="button-pag text-footer text-regular" @click="previousPage" v-if="previous">&lt; Anterior</button>
        <select class="text-footer text-semibold" name="" id="" @change="handlePage" v-model="pageSelect">
          <option :value="page" v-for="page in pages" :key="page">{{ page }}</option>
        </select>
        <button class="button-pag text-footer text-regular" @click="nextPage" v-if="next">Próximo &gt;</button>
      </div>
    </div>
  </div>
</template>
<script>

import { defineComponent, onMounted, ref } from 'vue'
import CustomerTable from '@/components/CustomerTable.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import { requestGet } from '@/lib/api'


export default defineComponent({
  name: 'CustomerPage',
  components: {
    LoadingComponent,
    CustomerTable
  },
  setup() {
    const param = ref(null)
    const pageSelect = ref(1)
    const loading = ref(true)
    const totalItems = ref(0);
    const currentPage = ref(0);
    const fromCount = ref(1);
    const toShownCount = ref(10);
    const next = ref(null);
    const previous = ref(null);

    const columns = [
      { field: 'id', name: "ID", type: 'uuid' },
      { field: 'fullName', name: "NOME", type: 'text' },
      { field: 'document', name: "DOCUMENTO", type: 'text' },
      { field: 'phone', name: "TELEFONE", type: 'phone' },
      { field: 'email', name: "E-MAIL", type: 'text' },
      { field: 'status', name: "STATUS", type: 'status' },
      { field: 'id', name: "", type: 'buttonPrimary', to: '/cliente/{}/detalhe' },
      { field: 'id', name: "", type: 'buttonSecondary', to: '/cliente/{}/movimentacoes' },
      { field: 'id', name: "", type: 'buttonDanger', to: '/' },
    ]
    const rows = ref([])

    const pages = ref([])

    onMounted(() => {
      getCustomers()
    })

    async function getCustomers() {
      loading.value = true
      const data = await requestGet('/account/search', {
        param: param.value ? param.value : '',
        page: currentPage.value
      })
      rows.value = data.data
      totalItems.value = data.totalReg
      currentPage.value = data.currentPage
      pageSelect.value = data.currentPage
      next.value = data.nextPage
      previous.value = data.previousPage
      if (currentPage.value === 1) {
        if (totalItems.value < 10) {
          toShownCount.value = totalItems.value
        } else {
          toShownCount.value = 10;
        }
      }
      if (currentPage.value > 1) {
        fromCount.value = (currentPage.value - 1) * 10
        toShownCount.value = fromCount.value + data.data.length
      }
      let pageAmount = totalItems.value / 10
      pages.value = []
      let count = 0
      while (count <= pageAmount) {
        pages.value.push(count + 1)
        count++
      }
      loading.value = false
    }

    function nextPage() {
      currentPage.value = currentPage.value + 1
      getCustomers()
    }

    function previousPage() {
      currentPage.value = currentPage.value - 1
      getCustomers()
    }

    function handlePage() {
      currentPage.value = pageSelect.value
      getCustomers()
    }

    return {
      columns,
      rows,
      param,
      getCustomers,
      loading,
      totalItems,
      currentPage,
      fromCount,
      toShownCount,
      pages,
      nextPage,
      previousPage,
      next,
      previous,
      handlePage,
      pageSelect
    }
  }

})
</script>