<template>
  <div class="customer-table-wrapper">
    <table class="customer-table">
      <thead>
        <tr>
          <th class="text-gray text-bold text-table" v-for="column in columns" :key="column.field">{{ column.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rowsTable" :key="row.id">
          <td class="text-regular text-table text-gray" v-for="(td, index) in row" :key="index">
            <router-link :to="td.redirectTo.replace('{}', td.name)" v-if="td.type === 'buttonPrimary'">
              <button class="button-detail text-bold text-table">
                Detalhes
              </button>
            </router-link>
            <router-link :to="td.redirectTo.replace('{}', td.name)" v-if="td.type === 'buttonSecondary'">
              <button class="button-transaction text-bold text-table">
                Transações
              </button>
            </router-link>
            <!-- <router-link :to="td.redirectTo.replace('{}', td.name)" v-if="td.type === 'buttonDanger'"> -->
            <div v-if="td.type === 'buttonDanger'">
              <button class="button-block text-bold text-table">
                Bloquear
              </button>
            </div>
            <!-- </router-link> -->
            <span v-if="td.type === 'text'">
              {{ td.name }}
            </span>
            <span v-if="td.type === 'phone'">
              {{ formatPhoneNumber(td.name) }}
            </span>
            <span v-if="td.type === 'uuid'">
              #{{ td.name.substring(0, 5) }}
            </span>
            <span v-if="td.type === 'representativeType'">
              <span class="" v-if="td.name === 'administrative_partner'">
                SÓCIO ADMINISTRATIVO </span>
              <span class="" v-if="td.name === 'owner'">
                PROPRIETÁRIO </span>
              <span class="" v-if="td.name === 'partner'">
                SÓCIO </span>
            </span>
            <span v-if="td.type === 'status'">
              <span class="" v-if="td.name === 'pending'">
                PENDENTE
              </span>
              <span class="" v-if="td.name === 'processing'">
                AGUARDANDO
              </span>
              <span class="active" v-if="td.name === 'approved'">
                ATIVO
              </span>
              <span class="" v-if="td.name === 'rejected'">
                REJEITADO
              </span>
              <span class="examination" v-if="td.name === 'examination'">
                MALHA-FINA
              </span>
              <span class="" v-if="td.name === 'without_account'">
                SÓCIO NÃO POSSUÍ CONTA
              </span>
              <span v-if="td.name === 'pending_representative_response'">
                PENDENTE RESPOSTA DO CLIENTE
              </span>
              <span class="active" v-if="td.name === 'accepted'">
                CLIENTE ACEITOU
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { defineComponent, reactive, toRef, watch } from 'vue'

export default defineComponent({
  name: 'CustomerTable',
  props: {
    loading: Boolean,
    columns: Array,
    rows: Array,
  },
  setup(props) {
    const rowsRef = toRef(props, 'rows')
    const columnsRef = toRef(props, 'columns')
    const rows = []
    const rowsTable = reactive([])

    const formatPhoneNumber = function (phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '')
      const formatted = cleaned.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
      return formatted
    }

    rowsRef.value.forEach(row => {
      let td = []
      columnsRef.value.forEach(data => {
        td.push({
          type: data.type,
          name: row[data.field],
          redirectTo: data.to
        })
      })
      rows.push(td)
    })

    const updateRowsTable = () => {
      rowsTable.splice(0, rowsTable.length) // Limpa o array
      props.rows.forEach(row => {
        let td = []
        props.columns.forEach(data => {
          td.push({
            type: data.type,
            name: row[data.field],
            redirectTo: data.to
          })
        })
        rowsTable.push(td)
      })
    }

    watch(() => props.rows, updateRowsTable, { immediate: true, deep: true })
    watch(() => props.columns, updateRowsTable, { immediate: true, deep: true })



    return {
      rowsTable,
      formatPhoneNumber,
    }
  },
  components: {

  },
})
</script>
