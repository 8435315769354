const intToRealBRL = function (value) {
  value = value / 100;
  return value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
};

const intToCurrencyK = function (value) {
  value = value / 100;
  let formattedValue = "";
  if (value >= 1000) {
    formattedValue = (value / 1000).toFixed(0) + "k";
  } else {
    formattedValue = value.toFixed(0);
  }
  return formattedValue;
};

//convert string brl to int cents
const brlToCents = function (value) {
  value = value.replace(/\D/g, '');
  return parseInt(value) * 100;
};



export { intToRealBRL, intToCurrencyK, brlToCents };
