<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :stroke="isActive ? 'var(--orange)' : 'white'" d="M1.42139 20.3584H20.5423" stroke-width="1.63"
      stroke-linecap="round" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M2.3269 20.3583L2.37085 8.81547C2.37085 8.22646 2.65218 7.67262 3.10932 7.31218L9.79943 2.0814C10.4851 1.54513 11.4522 1.54513 12.1555 2.0814L18.8456 7.30338C19.3115 7.67261 19.584 8.22646 19.584 8.82427V20.3671"
      stroke-width="1.63" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M11.9444 15.5669H10.0279C9.23673 15.5669 8.59497 16.2087 8.59497 17.0087V20.3669H13.3774V17.0087C13.3774 16.2087 12.7356 15.5669 11.9444 15.5669Z"
      stroke-width="1.63" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M8.59493 12.4457H6.67845C6.15098 12.4457 5.72021 12.0149 5.72021 11.4874V10.0456C5.72021 9.51817 6.15098 9.0874 6.67845 9.0874H8.59493C9.1224 9.0874 9.55317 9.51817 9.55317 10.0456V11.4874C9.55317 12.0149 9.1224 12.4457 8.59493 12.4457Z"
      stroke-width="1.63" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M15.2851 12.4457H13.3687C12.8412 12.4457 12.4104 12.0149 12.4104 11.4874V10.0456C12.4104 9.51817 12.8412 9.0874 13.3687 9.0874H15.2851C15.8126 9.0874 16.2434 9.51817 16.2434 10.0456V11.4874C16.2434 12.0149 15.8126 12.4457 15.2851 12.4457Z"
      stroke-width="1.63" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'" d="M17.6763 5.96703L17.6499 3.09229H13.439" stroke-width="1.63"
      stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DashboardIcon',
  props: {
    isActive: Boolean,
  },
})
</script>


