<template>
  <div class="content-body">
    <div class="header-border header-cancel">
      <h1 class="text-title text-bold">Verificação de conta</h1>
      <router-link :to="`/cliente/${route.params.id}/detalhe`"><img src="../assets/img/sair.svg" alt=""></router-link>
    </div>
    <div class="customer-form">
      <form action="">
        <fieldset class="customer-form-col-1">
          <div class="">
            <label class="text-customer-data text-bold text-gray" for="">Contrato Social</label>
            <input class="customer-input" type="file" id="file" name="file" accept=".pdf, .doc, .docx, jpg, .jpeg, .png"
              @change="handleFileChange($event, 'socialContract')">
          </div>
        </fieldset>
        <fieldset class="customer-form-col-1">
          <div class="">
            <label class="text-customer-data text-bold text-gray" for="">Faturamento últimos 12 meses</label>
            <input class="customer-input" type="file" id="file" name="file" accept=".pdf, .doc, .docx, jpg, .jpeg, .png"
              @change="handleFileChange($event, 'proofOfIncome')">
          </div>
          <br />
        </fieldset>
      </form>
      <div class="verify-pj-button">
        <button class="text-customer-button text-semibold button-malha-fina hover-black" type="submit"
          @click="handleVerifyButtonClicked" :disabled="loading">
          <span v-if="loading">
            <i class="fa fa-spinner fa-spin"></i>
          </span>
          <span v-else>
            Salvar
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { uploadFile, requestPutPublic } from '@/lib/api'
import { AlertUtils } from '@/utils/alert.js'


export default defineComponent({
  name: 'VerifyJuridicalCustomerPage',
  components: {},
  setup() {

    const loading = ref(false)
    const socialContract = ref(null)
    const proofOfIncome = ref(null)
    const socialContractUrl = ref(null)
    const proofOfIncomeUrl = ref(null)

    const handleFileChange = (event, type) => {
      if (type === 'socialContract') {
        socialContract.value = event.target.files[0]
      } else if (type === 'proofOfIncome') {
        proofOfIncome.value = event.target.files[0]
      }
    }


    const validateFiles = () => {
      if (!socialContract.value) {
        AlertUtils.error('Por favor, selecione o arquivo do Contrato Social.')
        return false
      }

      if (!proofOfIncome.value) {
        AlertUtils.error('Por favor, selecione o arquivo do Faturamento dos últimos 12 meses.')
        return false
      }

      return true
    }

    const route = useRoute()
    const router = useRouter()


    const handleVerifyButtonClicked = async () => {

      validateFiles()
      if (!validateFiles()) return
      loading.value = true

      try {
        const [socialContractResponse, proofOfIncomeResponse] = await Promise.all([
          _uploadFile(socialContract.value),
          _uploadFile(proofOfIncome.value)
        ])

        socialContractUrl.value = socialContractResponse.token
        proofOfIncomeUrl.value = proofOfIncomeResponse.token
      } catch (e) {
        AlertUtils.error('Erro ao fazer upload do arquivo')
        throw e
      }

      try {
        const response = await _verifyAccount()
        console.log(response)
      } catch (e) {
        return
      }
      loading.value = false
    }

    const _uploadFile = async (file) => {
      const formData = new FormData()
      formData.append('file', file)
      const response = await uploadFile(formData)
      if (!response.token) throw new Error()
      return response
    }

    const _verifyAccount = async () => {

      const uploaderUrl = "https://uploader.homecorp.com.br"

      const dataToSend = {
        "documentType": 'social_contract',
        'socialContract': { "url": `${uploaderUrl}/?token=${socialContractUrl.value}` },
        'proofOfIncome': { "url": `${uploaderUrl}/?token=${proofOfIncomeUrl.value}` },
      }

      console.log(dataToSend)

      try {
        const response = await requestPutPublic(`ws/account/verify/${route.params.id}`, dataToSend)
        console.log(response)
        loading.value = false
        const closed = await AlertUtils.success('Conta verificada com sucesso')
        if (closed) router.push(`/cliente/${route.params.id}/detalhe`)
      } catch (e) {
        if (e.response.data.message) {
          const message = e.response.data.message
          if (message === 'Not all representatives have accepted') {
            AlertUtils.error('Não foi possível verificar a conta, pois nem todos os representantes aceitaram.')
            return;
          }
          if (message === 'At least one representative must have a RepresentativeType of Owner') {
            AlertUtils.error('Não foi possível verificar a conta, pois pelo menos um representante deve ser Proprietário.')
            return;
          }
          if (message.includes('There is already an approved or running KYC')) {
            AlertUtils.error('Não foi possível verificar a conta, pois já existe uma verificação aprovado ou em andamento.')
            return;
          }
        }
        console.log(e)
        AlertUtils.error('Erro ao verificar conta')
      }

    }

    return {
      handleVerifyButtonClicked,
      loading,
      handleFileChange,
      route
    }
  }

})
</script>