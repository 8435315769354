<template>
  <div class="dashboard-body">
    <div class="header">
      <div class="text-title text-bold">Bom dia, {{ 'João' }}!</div>
    </div>
    <div class="dashboard-row-1">
      <DashboardCard v-for="(card, index) in cards" :key="index" :icon="card.icon" :alt="card.alt" :type="card.type"
        :data="card.data" :line1="card.line1" :line2="card.line2" />
    </div>
    <div class="dashboard-row-2">
      <div class="dashboard-row-2-col-1">
        <div class="dashboard-row-2-card-1 text-bold">
          <p class="text-dashboard-card-title">Total de Clientes</p>
          <p class="text-dashboard-card-number-2">{{ formatedData.customers }}</p>
        </div>
        <div class="dashboard-row-2-card-2 text-bold">
          <p class="text-dashboard-card-title">Malha Fina</p>
          <p class="text-dashboard-card-number-2"> {{ formatedData.examination }} </p>
        </div>
      </div>
      <div class="dashboard-row-2-col-2">
        <div class="dashboard-row-2-graph">
          <VueApexCharts height="100%" width="100%" :options="chartOptions" :series="series">
          </VueApexCharts>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import DashboardCard from '@/components/DashboardCard.vue';
import VueApexCharts from "vue3-apexcharts";
import { intToRealBRL, intToCurrencyK } from '../utils/coin.js';
import { formatDateMonthDay } from '../utils/date';

export default defineComponent({
  name: 'DashboardPage',
  components: {
    VueApexCharts,
    DashboardCard,
  },
  setup() {

    const data = ref(
      {
        transactions: "32",
        balance: "236545",
        agencies: "80",
        weeklyRevenue: "4379000",
        customers: "54",
        examination: "7",
        currentWeekDays: ['2023-02-14 12:00:00', '2023-02-15 12:00:00', '2023-02-16 12:00:00', '2023-02-17 12:00:00', '2023-02-18 12:00:00', '2023-02-19 12:00:00', '2023-02-20 12:00:00'],
        currentWeekRevenue: [1400000, 1550000, 1650000, 1620000, 1820000, 1780000, 2000000],
        previousWeekRevenue: [1550000, 1340000, 1200000, 1760000, 1630000, 1580000, 1750000],
      });

    const formatedData = {
      transactions: data.value.transactions,
      balance: intToRealBRL(data.value.balance),
      agencies: data.value.agencies,
      weeklyRevenue: intToRealBRL(data.value.weeklyRevenue),
      customers: data.value.customers,
      examination: data.value.examination,
      currentWeekDays: data.value.currentWeekDays.map(formatDateMonthDay),
      currentWeekRevenue: data.value.currentWeekRevenue,
      previousWeekRevenue: data.value.previousWeekRevenue,
    }


    const cards = [
      { icon: "transaction.svg", alt: 'transaction', type: 'number', data: formatedData.transactions, line1: "Transações", line2: "realizadas" },
      { icon: "balance.svg", alt: 'balance', type: 'value', data: formatedData.balance, line1: "Saldo em", line2: "conta" },
      { icon: "agencies.svg", alt: 'agencies', type: 'number', data: formatedData.agencies, line1: "Agências", line2: "ativas" },
      { icon: "revenue.svg", alt: 'revenue', type: 'value', data: formatedData.weeklyRevenue, line1: "Receita", line2: "Semanal" },
    ]

    const series = [
      {
        name: 'Últimos 7 dias',
        data: formatedData.currentWeekRevenue,
      },

      {
        name: 'Semana anterior',
        data: formatedData.previousWeekRevenue,

      }
    ];

    const chartOptions = {

      chart: {
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },

      },

      colors: ['var(--greenblue)', 'var(--red)'],
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Receita',
        style: {
          fontSize: '22px',
          fontFamily: 'Gilroy-Bold',
          color: '#000000',
        },

      },
      subtitle: {
        text: 'Últimos 7 dias vs. semana anterior',
        align: 'right',
        offsetX: 0,
        offsetY: 0,
        floating: true,
        style: {
          fontSize: '15px',
          fontWeight: 'normal',
          fontFamily: 'Gilroy-Regular',
          color: '#000000;'
        },
      },
      legend: {
        show: false,
      },

      grid: {
        row: {
          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        },
      },
      xaxis: {
        categories: formatedData.currentWeekDays,
        labels: {
          style: {
            colors: ['rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)'],
            fontSize: '14px',
            fontFamily: 'Gilroy-Regular',
          },
        },
      },
      yaxis: {
        // min: 0,
        tickAmount: 4,
        labels: {
          formatter: function (value) {
            return intToCurrencyK(value);
          },
          style: {
            colors: ['#FF0023', 'rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)', 'rgba(29, 25, 86, 0.52)'],
            fontSize: '14px',
            fontFamily: 'Gilroy-Regular',
          },
        },
      }

    }
    return {
      data,
      series,
      chartOptions,
      cards,
      formatedData
    }
  }
})
</script>