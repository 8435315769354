<template>
  <div class="content-body">
    <div class="header-border header-cancel">
      <h1 class="text-title text-bold">Criar Cliente Pessoa Jurídica</h1>
      <router-link :to="`/clientes`"><img src="../assets/img/sair.svg" alt=""></router-link>
    </div>
  </div>
  <div class="customer-form">
    <form action="">
      <fieldset class="customer-form-col-1">
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Razão social</label>
          <div class="input-error">
            <input v-model="companyRegister.socialName" class="customer-input text-customer-data text-regular text-gray"
              placeholder="Razão Social" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.socialName">{{ errors.socialName }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Nome Fantasia</label>
          <div class="input-error">
            <input v-model="companyRegister.fullName" class="customer-input text-customer-data text-regular text-gray"
              placeholder="Nome Fantasia" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.fullName">{{ errors.fullName }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">CNPJ</label>
          <div class="input-error">
            <input v-model="companyRegister.document" class="customer-input text-customer-data text-regular text-gray"
              placeholder="CNPJ" type="text" v-mask="'##.###.###/####-##'">
            <p class="text-input-error text-red customer-input-error" v-if="errors.document">{{ errors.document }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Domicilio Fiscal</label>
          <div class="input-error">
            <select v-model="companyRegister.fiscalCountry" class="customer-select text-customer-data text-bold text-gray"
              name="" id="">
              <option value="BRA">Brasil</option>
            </select>
            <p class="text-input-error text-red customer-input-error" v-if="errors.fiscalCountry">{{ errors.fiscalCountry
            }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Rua</label>
          <div class="input-error">
            <input v-model="companyRegister.addresses.streetName"
              class="customer-input text-customer-data text-regular text-gray" placeholder="Nome rua" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.streetName">{{ errors.streetName }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Número</label>
          <div class="input-error">
            <input v-model="companyRegister.addresses.streetNumber"
              class="customer-input text-customer-data text-regular text-gray" placeholder="Número" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.streetNumber">{{ errors.streetNumber
            }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Bairro</label>
          <div class="input-error">
            <input v-model="companyRegister.addresses.neighborhood"
              class="customer-input text-customer-data text-regular text-gray" placeholder="Bairro" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.neighborhood">{{ errors.neighborhood
            }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Complemento</label>
          <div class="input-error">
            <input v-model="companyRegister.addresses.complement"
              class="customer-input text-customer-data text-regular text-gray" placeholder="Complemento" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.complement">{{ errors.complement }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">CEP</label>
          <div class="input-error">
            <input v-model="companyRegister.addresses.zipcode" v-mask="'#####-###'"
              class="customer-input text-customer-data text-regular text-gray" placeholder="CEP" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.zipcode">{{ errors.zipcode }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Cidade</label>
          <div class="input-error">
            <input v-model="companyRegister.addresses.city"
              class="customer-input text-customer-data text-regular text-gray" placeholder="Cidade" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.city">{{ errors.city }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">UF</label>
          <div class="input-error">
            <select v-model="companyRegister.addresses.uf" class="customer-select text-customer-data text-bold text-gray"
              name="">
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>
            <p class="text-input-error text-red customer-input-error" v-if="errors.uf">{{ errors.uf }}</p>
          </div>
        </div>
      </fieldset>
      <fieldset class="customer-form-col-1">
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Data de abertura</label>
          <div class="input-error">
            <input v-model="companyRegister.openingCompanyDate"
              class="customer-input text-customer-data text-regular text-gray" placeholder="Data de abertura" type="text"
              v-mask="'##/##/####'">
            <p class="text-input-error text-red customer-input-error" v-if="errors.openingCompanyDate">
              {{ errors.openingCompanyDate }}
            </p>
          </div>
        </div>

        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Patrimônio</label>
          <div class="input-error">
            <money3 v-bind="config" v-model.lazy="companyRegister.patrimony"
              class="money3 text-customer-data text-regular text-gray" placeholder="Patrimônio" type="text">
            </money3>
            <p class="text-input-error text-red customer-input-error" v-if="errors.patrimony">{{ errors.patrimony }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Faturamento Mensal</label>
          <div class="input-error">
            <money3 v-bind="config" v-model.lazy="companyRegister.monthlyIncome"
              class="money3 text-customer-data text-regular text-gray" placeholder="Faturamento Mensal" type="text">
            </money3>
            <p class="text-input-error text-red customer-input-error" v-if="errors.monthlyIncome">{{
              errors.monthlyIncome
            }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">E-mail</label>
          <div class="input-error">
            <input v-model="companyRegister.email" class="customer-input text-customer-data text-regular text-gray"
              placeholder="loremipsum@gmail.com" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.email">{{ errors.email }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Telefone</label>
          <div class="input-error">
            <input v-model="companyRegister.phone" class="customer-input text-customer-data text-regular text-gray"
              placeholder="(##) #####-####" type="text" v-mask="'(##) #####-####'">
            <p class="text-input-error text-red customer-input-error" v-if="errors.phone">{{ errors.phone }}</p>
          </div>
        </div>
        <div class="input-group">
          <label class="text-customer-data text-bold text-gray" for="">Linha de negócio</label>
          <div class="input-error">
            <input v-model="companyRegister.businessLine" class="customer-input text-customer-data text-regular text-gray"
              placeholder="Sociedade Empresária Limitada" type="text">
            <p class="text-input-error text-red customer-input-error" v-if="errors.businessLine">{{ errors.businessLine
            }}</p>
          </div>
        </div>
        <br />
        <div class="form-button">
          <button class="text-customer-button text-semibold button-malha-fina hover-black" type="submit"
            @click="handleRegisterCompany" :disabled="loading">
            <span v-if="loading">
              <i class="fa fa-spinner fa-spin"></i>
            </span>
            <span v-else>
              Salvar
            </span>
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>
<script>

import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { requestPostPublic } from '@/lib/api'
import { validateEmail, validatePhone, validateCNPJ, validateCEP, validateGreaterThanZero, validateDate } from '@/utils/validator.js'
import { Money3Component } from 'v-money3'
import { formatDateToDB } from '@/utils/date.js'
import { brlToCents } from '@/utils/coin.js'
import { AlertUtils } from '@/utils/alert.js'



export default defineComponent({
  name: 'CreateJuridicalCustomer',
  components: {
    money3: Money3Component,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const loading = ref(false)
    const errors = ref({})

    const config = ref({
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 0,
      masked: true
    })
    const validateFields = () => {
      errors.value = {}

      if (!companyRegister.value.socialName) errors.value.socialName = 'Digite um Nome Social válido';
      if (!companyRegister.value.fullName) errors.value.fullName = 'Digite um Nome Fantasia válido';
      if (!validateCNPJ(companyRegister.value.document)) errors.value.document = 'Digite um CNPJ válido';
      if (!companyRegister.value.fiscalCountry) errors.value.fiscalCountry = 'Digite um Domicilio Fiscal válido';
      if (!validateGreaterThanZero(companyRegister.value.patrimony)) errors.value.patrimony = 'Digite um Patrimônio válido';
      if (!validateGreaterThanZero(companyRegister.value.monthlyIncome)) errors.value.monthlyIncome = 'Digite um Faturamento Mensal válido';
      if (!validateEmail(companyRegister.value.email)) errors.value.email = 'Digite um E-mail válido';
      if (!validatePhone(companyRegister.value.phone)) errors.value.phone = 'Digite um Telefone válido';
      if (!companyRegister.value.businessLine) errors.value.businessLine = 'Digite uma Linha de negócio válida';
      if (!validateDate(companyRegister.value.openingCompanyDate)) errors.value.openingCompanyDate = 'Digite uma Data de abertura válida';
      if (!companyRegister.value.addresses.streetName) errors.value.streetName = 'Digite uma Rua válida';
      if (!companyRegister.value.addresses.streetNumber) errors.value.streetNumber = 'Digite um Número válido';
      if (!companyRegister.value.addresses.neighborhood) errors.value.neighborhood = 'Digite um Bairro válido';
      if (!companyRegister.value.addresses.complement) errors.value.complement = 'Digite um Complemento válido';
      if (!validateCEP(companyRegister.value.addresses.zipcode)) errors.value.zipcode = 'Digite um CEP válido';
      if (!companyRegister.value.addresses.city) errors.value.city = 'Digite uma Cidade válida';
      if (!companyRegister.value.addresses.uf) errors.value.uf = 'Digite um UF válido';
    }

    const companyRegister = ref({
      socialName: '',
      fullName: '',
      document: '',
      documentType: 'cnpj',
      fiscalCountry: '',
      patrimony: '',
      monthlyIncome: '',
      email: '',
      phone: '',
      businessLine: '',
      isPep: false,
      openingCompanyDate: '',
      addresses: {
        streetName: '',
        streetNumber: '',
        neighborhood: '',
        complement: '',
        zipcode: '',
        city: '',
        uf: '',
        main: true
      },
    })

    const handleRegisterCompany = async () => {
      const dataToSend = { ...companyRegister.value };
      dataToSend.document = dataToSend.document.replace(/\D/g, '');
      dataToSend.patrimony = brlToCents(dataToSend.patrimony);
      dataToSend.monthlyIncome = brlToCents(dataToSend.monthlyIncome);
      dataToSend.phone = dataToSend.phone.replace(/\D/g, '');
      dataToSend.addresses.zipcode = dataToSend.addresses.zipcode.replace(/\D/g, '');
      dataToSend.addresses = [dataToSend.addresses];
      dataToSend.openingCompanyDate = formatDateToDB(dataToSend.openingCompanyDate);

      validateFields()
      if (Object.keys(errors.value).length > 0) {
        return
      }
      errors.value = {}

      loading.value = true
      try {
        const response = await requestPostPublic('ws/account/create', dataToSend)
        router.push(`/cliente/${response.id}/detalhe`)
        console.log(response)
      } catch (error) {
        AlertUtils.error('Erro ao criar cliente')
        console.error(error)
      }
      loading.value = false

    }

    return {
      route,
      companyRegister,
      handleRegisterCompany,
      loading,
      errors,
      config
    }
  }

})
</script>