<template>
  <div class="content-body">
    <div class="header-border header">
      <h1 class="text-title text-bold">Transações Cliente: {{ customerDetail.fullName }}</h1>
    </div>
    <div class="transaction-header">
      <div class="transaction-titles">
        <div class="text-title text-bold"> Número da conta: {{ }} </div>
        <div class="text-title text-bold"> Saldo em conta: {{ formatAmount(customerDetail.balance) }} </div>
      </div>
      <button @click="openCashIn" class="button-add text-semibold">Adicionar Saldo</button>
      <div class="transaction-filter" v-if="false">
        <div class="transaction-filter-item">
          <label class="text-table-title text-semibold" for="initialDate">Data inicial:</label>
          <input class="text-table-title text-regular" type="date" id="initialDate">
        </div>
        <div class="transaction-filter-item">
          <label class="text-table-title text-semibold" for="finalDate">Data final:</label>
          <input class="text-table-title text-regular" type="date" id="finalDate">
        </div>
        <div class="transaction-filter-item">
          <button class="button-filter" type="submit" @click="getCustomers"><img class="img-search"
              src="../assets/svg/Search.svg" alt=""></button>
        </div>
        <div class="transaction-filter-item">
          <button @click="openExport" class="button-filter"><img class="img-export" src="../assets/svg/export.svg"
              alt=""></button>
        </div>
      </div>
    </div>
    <LoadingComponent :columns="columns" v-if="loading" />
    <TransactionTable :columns="columns" :rows="rows" v-if="!loading" />
    <div v-if="!loading" class="customer-footer">
      <div class="" v-if="false">
        <p class="text-footer text-regular">Exibindo de {{ fromCount }} a {{ toShownCount }} de {{ totalItems }} itens</p>
      </div>
      <div class="" v-if="false">
        <button class="button-pag text-footer text-regular" @click="previousPage" v-if="previous">&lt; Anterior</button>
        <select class="text-footer text-semibold" name="" id="" @change="handlePage" v-model="pageSelect">
          <option :value="page" v-for="page in pages" :key="page">{{ page }}</option>
        </select>
        <button class="button-pag text-footer text-regular" @click="nextPage" v-if="next">Próximo &gt;</button>
      </div>
    </div>
    <div class="overlay" v-show="showCashIn">
      <div class="modal">
        <button class="close-btn" @click="closeCashIn"><img src="../assets/svg/close.svg" alt=""></button>
        <div class="modal-cashin">
          <div v-if="!cashInError" :class="{ 'jump': cashInJump }" class="text-bold text-title">Digite o valor a ser
            adicionado</div>
          <div v-if="cashInError" :class="{ 'jump': cashInJump }" class="text-bold text-title">Ocorreu um erro, tente
            novamente</div>
          <div>
            <span class="text-bold text-title text-top">R$</span>
            <money3 class="text-bold text-cashin-value cashin-input" v-model="cashIn" v-bind="config"
              v-autowidth="{ maxWidth: '400px', minWidth: '50px', comfortZone: 0 }"
              :disabled="loadingCashin || cashinCompleted">
            </money3>
          </div>
          <button v-if="!cashinCompleted" class="button-add-modal text-semibold" @click="handleCashIn"
            :disabled="loadingCashin">
            <span v-if="!loadingCashin">Adicionar</span>
            <span v-if="loadingCashin" class="loading-icon"></span>
          </button>
          <span v-if="cashinCompleted" class="text-greenblue text-bold text-title button-sucess-msg">
            Saldo adicionado com sucesso!
          </span>
        </div>
      </div>
    </div>
    <div class="overlay" v-show="showExport">
      <div class="modal">
        <button class="close-btn" @click="closeExport"><img src="../assets/svg/close.svg" alt=""></button>
        <div class="modal-export">
          <div v-if="!exportError" class="text-bold text-title">
            Exportar Extrato
          </div>
          <div v-if="exportError" :class="{ 'jump': exportJump }" class="text-bold text-title">Ocorreu um erro, tente
            novamente</div>
          <div class="extract-filters">
            <div v-for="(checkbox, index) in exportCheckboxes" :key="index">
              <label class="extract-checkbox">
                <input type="checkbox" v-model="checkbox.checked" />
                <span class="export-checkmark"></span>
                <span class="text-semibold text-extract-filter">{{ checkbox.label }}</span>
              </label>
            </div>
          </div>
          <button v-if="!exportCompleted" class="button-add-modal text-semibold" @click="handleExport"
            :disabled="loadingExport">
            <span v-if="!loadingExport">Exportar</span>
            <span v-if="loadingExport" class="loading-icon"></span>
          </button>
          <span v-if="exportCompleted" class="text-greenblue text-bold text-title button-sucess-msg">
            Extrato exportado com sucesso!
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import TransactionTable from '@/components/TransactionTable.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import { requestGet, requestPut } from '@/lib/api'
import { useRoute } from 'vue-router'
import { intToRealBRL } from '@/utils/coin'
import { Money3Component } from 'v-money3'
import { directive as VueInputAutowidth } from "vue-input-autowidth"

export default defineComponent({
  name: 'CustomerTransactionPage',
  directives: { autowidth: VueInputAutowidth },
  components: {
    money3: Money3Component,
    TransactionTable,
    LoadingComponent
  },
  setup() {


    const route = useRoute()

    const param = ref(null)
    const pageSelect = ref(1)
    const loading = ref(false)
    const totalItems = ref(0);
    const currentPage = ref(0);
    const fromCount = ref(1);
    const toShownCount = ref(10);
    const next = ref(null);
    const previous = ref(null);

    const options = ref([])
    const selectedOption = ref('')

    const showCashIn = ref(false);
    const loadingCashin = ref(false);
    const cashinCompleted = ref(false);
    const cashInError = ref(false);
    const cashInJump = ref(false);
    const cashIn = ref('');

    const showExport = ref(false);
    const loadingExport = ref(false);
    const exportCompleted = ref(false);
    const exportError = ref(false);
    const exportJump = ref(false);
    const exportCheckboxes = ref([
      { field: 'filter1', label: 'Opção 1', checked: false },
      { field: 'filter2', label: 'Opção 2', checked: false },
      { field: 'filter3', label: 'Opção 3', checked: false },
      { field: 'filter4', label: 'Opção 4', checked: false },
      { field: 'filter5', label: 'Opção 5', checked: false },
    ])

    const columns = [
      { field: 'createdAt', name: "Data", type: 'date' },
      { field: 'title', name: "Transação", type: 'text' },
      // { field: 'detailType', name: "Serviço", type: 'service' },
      { field: 'type', name: "Situação", type: 'situation' },
      { field: 'status', name: "Status", type: 'status' },
      { field: 'amount', name: "Valor", type: 'value' },
      { field: 'receipt', name: "Comprovante", type: 'receipt', to: '/cliente/{}/detalhe' },
    ]
    const rows = ref([]);

    const pages = ref([])

    const customerDetail = ref({
      id: '',
      fullName: '',
      document: '',
      documentType: '',
      birthDate: '',
      citizenship: '',
      mothersName: '',
      gender: '',
      patrimony: 0,
      monthlyIncome: 0,
      phone: '',
      email: '',
      createdAt: '',
      verified: false,
      status: '',
      balance: 0,
      mainAddress: {
        streetName: '',
        streetNumber: '',
        neighborhood: '',
        zipcode: '',
        city: '',
        uf: '',
      }
    })

    const config = ref({
      masked: true,
      prefix: '',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
      shouldRound: true,
      focusOnRight: true,
    })



    onMounted(() => {
      getCustomers()
      getTenantStatement();
    })

    async function getTenantStatement() {
      loading.value = true
      let result = await requestGet(`/account/statements/${route.params.id}`)
      console.log(result.data)
      rows.value = result.data
      loading.value = false
    }

    async function getCustomers() {
      let result = await requestGet(`/account/detail/${route.params.id}`)
      console.log(result);
      customerDetail.value = result
    }

    function nextPage() {
      currentPage.value = currentPage.value + 1
    }

    function previousPage() {
      currentPage.value = currentPage.value - 1
    }

    function handlePage() {
      currentPage.value = pageSelect.value
    }

    function openCashIn() {
      showCashIn.value = true
    }

    function formatAmount(value) {
      return intToRealBRL(value)
    }

    function closeCashIn() {
      showCashIn.value = false;
      loadingCashin.value = false
      cashIn.value = '';
      cashinCompleted.value = false;
      cashInError.value = false;
    }

    async function handleCashIn() {
      console.log(cashIn.value);
      const amount = cashIn.value.replace(/\D/g, "");
      if (amount === '000' || amount === '' || amount === null) {
        cashInJump.value = true;
        setTimeout(() => {
          cashInJump.value = false;
        }, 1000);
        return
      }

      loadingCashin.value = true;
      try {
        const result = await requestPut(
          'account/cashin',
          route.params.id,
          {
            amount: amount
          })

        if (result === null) {
          cashinCompleted.value = true
          await getCustomers()
        }
      } catch (e) {
        cashIn.value = '';
        cashInError.value = true;
        cashInJump.value = true;
        setTimeout(() => {
          cashInJump.value = false;
        }, 1000);
      }
      loadingCashin.value = false;

    }

    function openExport() {
      showExport.value = true
    }

    function closeExport() {
      showExport.value = false;

    }

    function handleExport() {
      console.log(exportCheckboxes.value)
    }


    return {
      handleExport,
      exportCheckboxes,
      showExport,
      openExport,
      closeExport,
      loadingExport,
      exportCompleted,
      exportError,
      exportJump,
      cashinCompleted,
      cashInError,
      cashInJump,
      loadingCashin,
      config,
      handleCashIn,
      cashIn,
      showCashIn,
      openCashIn,
      closeCashIn,
      options,
      selectedOption,
      columns,
      rows,
      param,
      loading,
      totalItems,
      currentPage,
      fromCount,
      toShownCount,
      pages,
      getCustomers,
      nextPage,
      previousPage,
      next,
      previous,
      handlePage,
      pageSelect,
      customerDetail,
      formatAmount
    }
  }

})
</script>