import validator from 'validator'
import { CNPJ, CPF } from 'cpf_cnpj'
import moment from 'moment';


export function validateEmail(email) {
  return validator.isEmail(email);
}

export function validatePhone(phone) {
  if (!phone) {
    return false;
  }
  return validator.isMobilePhone(phone, 'pt-BR');
}

export function validateCNPJ(cnpj) {
  return CNPJ.isValid(cnpj);
}

export function validateCPF(cpf) {
  return CPF.isValid(cpf);
}

export function validateCEP(cep) {
  return /^[0-9]{5}-?[0-9]{3}$/.test(cep);
}

export function validateGreaterThanZero(value) {
  if (typeof value !== 'string') {
    return false;
  }

  const numberString = value.replace(/\D/g, '');
  const numberValue = Number(numberString);

  return !isNaN(numberValue) && numberValue > 0;
}

export function validateDate(date) {
  return moment(date, 'DD/MM/YYYY', true).isValid();
}