<template>
  <div class="dashboard-row-1-card">
    <img :src="require(`../assets/svg/${icon}`)" :alt="alt">
    <p v-if="type === 'number'" class="text-dashboard-card-number text-bold">{{ data }}</p>
    <p v-if="type === 'value'" class="text-dashboard-card-value text-bold">{{ data }}</p>
    <div class="text-dashboard-card text-regular">
      <p>{{ line1 }}</p>
      <p>{{ line2 }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DashboardCard',
  props: {
    icon: {
      type: String,
    },
    alt: {
      type: String,
    },
    type: {
      type: String,
    },
    data: {
      type: String,
    },
    line1: {
      type: String,
    },
    line2: {
      type: String,
    },
  },

});
</script>