import axios from "axios";
import router from "@/routes/routes";
import { Buffer } from "buffer";

const BASE_URL = "https://ln6alursuc.execute-api.sa-east-1.amazonaws.com";
const UPLOADER_URL = "https://uploader.homecorp.com.br";
const API_KEY = "AE20DD63-F765-4002-ADFC-B8BB6B532856";
const AUTHORIZATION = "YXBwX3BhdHJpYV9wcm9kXzgzNjU3NDIzOkM4QTk4QzhFXyo3MzRtaEpzVVk2M0AjXCQpXzU0QjZBOUFCQkJCQw==";

const refreshToken = async function () {
  if (!localStorage.getItem("refreshToken")) {
    logout();
    throw new Error();
  }
  const headers = {
    "Api-Key": API_KEY,
  };
  const payloadRequest = {
    refreshToken: localStorage.getItem("refreshToken"),
  };
  try {
    const response = await axios.post(
      BASE_URL + "/authentication/refreshtoken",
      payloadRequest,
      { headers }
    );
    const { token, refreshToken, tenant, scopes } = response.data;
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("tenant", tenant);
    localStorage.setItem("scopes", JSON.stringify(scopes));
  } catch (error) {
    return logout();
  }
};

const login = async function (username, password) {
  const auth = `${username}:${password}`;
  const encodedAuth = Buffer.from(auth).toString("base64");
  const headers = {
    Authorization: `Basic ` + encodedAuth,
    "Api-Key": API_KEY,
  };
  try {
    const response = await axios.post(BASE_URL + "/authentication", null, {
      headers,
    });
    const { token, refreshToken, tenant, scopes } = response.data;
    console.log(response);
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("tenant", tenant);
    localStorage.setItem("scopes", JSON.stringify(scopes));
  } catch (error) {
    if (error.response.status === 401) {
      return {
        errorLogin:
          "Login ou senha incorretos. Verifique suas credenciais e tente novamente.",
      };
    } else {
      return {
        errorLogin: "Ocorreu um erro. Tente novamente mais tarde.",
      };
      
    }
  }
};

const logout = function () {
  // Remover os itens do localStorage
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tenant');
  localStorage.removeItem('scopes');
  localStorage.removeItem('userData');

  router.replace('/login');

};


const requestGet = async function (path, params) {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Api-Key": API_KEY
  };
  params = new URLSearchParams(params);
  try {
    const response = await axios.get(
      `${BASE_URL}${path}?${params.toString()}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await refreshToken();
      return requestGet(path, params);
    }
    throw error;
  }
};

const requestPost = async function (path, body, url) {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Api-Key": API_KEY,
  };

  const requestUrl = url || `${BASE_URL}/${path}`;

  try {
    const response = await axios.post(requestUrl, body, {
      headers,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await refreshToken();
      return requestPost(path, body, url);
    }
    throw error;
  }
};

const requestPut = async function (path, id, body) {
  const token = localStorage.getItem("token");
  console.log(`${BASE_URL}/${path}/${id}`);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Api-Key": API_KEY,
  };
  try {
    const response = await axios.put(`${BASE_URL}/${path}/${id}`, body, {
      headers,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await refreshToken();
      return requestPut(path, id, body);
    }
    throw error;
  }
};

const requestDelete = async function (path, id) {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Api-Key": API_KEY,
  };
  try {
    const response = await axios.delete(`${BASE_URL}${path}/${id}`, null, {
      headers,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await refreshToken();
      return requestDelete(path, id);
    }
    throw error;
  }
};

const requestPostPublic = async function (path, body) {
  const headers = {
    Authorization: `Basic ${AUTHORIZATION}`,
    "Api-Key": API_KEY,
  };

    const response = await axios.post(`${BASE_URL}/${path}`, body, {
      headers,
    });
    return response.data;
};

const requestPutPublic = async function (path, body) {
  const headers = {
    Authorization: `Basic ${AUTHORIZATION}`,
    "Api-Key": API_KEY,
  };

    const response = await axios.put(`${BASE_URL}/${path}`, body, {
      headers,
    });
    return response.data;
};

const uploadFile = async function (body) {
  const headers = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

    const response = await axios.post(UPLOADER_URL, body, {
      headers,
    });
    return response.data;
};

export { login, logout, requestGet, requestPost, requestPut, requestDelete, requestPostPublic, uploadFile, requestPutPublic };
