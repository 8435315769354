<template>
  <div class="login-page">
    <div class="login-container">
      <div class="login-card">
        <img src="../assets/img/adaga-logo-orange.png" alt="">
        <div class="login-card-title">Seja bem-vindo.</div>
        <div class="login-error" v-if="errorLogin">{{ errorLogin }}</div>
        <form class="" @submit.prevent="handleLogin">
          <input class="login-input" type="text" v-model="username" placeholder="Login">
          <input class="login-input" type="password" v-model="password" placeholder="Senha">
          <button class="login-button login-button-text hover-gray" type="submit" :disabled="loading">
            {{ loading ? 'Carregando...' : 'Login' }}</button>
        </form>
        <div class="login-contact">
          <router-link to="/contato">
            <p class="login-contact-text">Está com alguma dúvida? <br> Fale conosco.</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { login } from '@/lib/api.js';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LoginPage',
  setup() {

    const router = useRouter()

    const loading = ref(false);
    const username = ref('');
    const password = ref('');
    const errorLogin = ref('');

    const handleLogin = async () => {

      if (!username.value) {
        return errorLogin.value = 'Por favor, insira seu nome de usuário.'
      }
      if (!password.value) {
        return errorLogin.value = 'Por favor, insira sua senha.'
      }
      loading.value = true


      try {
        const response = await login(username.value, password.value);

        if (response && response.errorLogin) {
          loading.value = false;
          return errorLogin.value = response.errorLogin
        }
        return router.push("/dashboard");
      } catch (error) {
        console.log(error);
        loading.value = false;
      }

    }

    return {
      loading,
      username,
      password,
      errorLogin,
      handleLogin,
    }
  }
})
</script>