<template>
  <div class="loading">
    <div class="loading-spinner"></div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingComponent',
  props: {
    loading: Boolean,
    columns: Array,
  },

})
</script>
