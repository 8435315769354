import Swal from 'sweetalert2'

export class AlertUtils {

  static success = async (message) => {
    await Swal.fire({
      title: 'Sucesso!',
      text: message,
      icon: 'success',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'button-malha-fina'
      },
      buttonsStyling: false
    })
    
    return true

  }

  static error = async (message) => {
    await Swal.fire({
      title: 'Erro!',
      text: message,
      icon: 'error',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'button-malha-fina'
      },
      buttonsStyling: false
    })
  
    return true
  }

}