<template>
  <div class="transaction-table-wrapper">
    <table class="transaction-table">
      <thead>
        <tr>
          <th class="text-transaction-table-title text-semibold" v-for="column in columns" :key="column.field"
            :class="getColumnClass(column.type)">
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rowsTable" :key="row.id">
          <td class="text-transaction-table text-regular text-gray" v-for="(td, index) in row" :key="index"
            :class="getColumnClass(td.type)">
            <!-- <router-link :to="td.redirectTo.replace('{}', td.name)" v-if="td.type === 'receipt'"> -->
            <div v-if="td.type === 'receipt'">
              <button class="button-receipt text">
                <img src="../assets/img/receipt.png" alt="">
              </button>
            </div>
            <!-- </router-link> -->
            <span v-if="td.type === 'date'">
              {{ formatDateToForm(td.name) }}
            </span>
            <span v-if="td.type === 'text'">
              {{ td.name }}
            </span>
            <!-- <span class="text-semibold" v-if="td.type === 'service'">
              {{ services[td.name] }}
            </span> -->
            <span class="text" v-if="td.type === 'value'">
              {{ formatCentsToBRL(td.name) }}
            </span>
            <span v-if="td.type === 'situation'">
              <span class="text-greenblue" v-if="td.name === 'credit'">
                entrada
              </span>
              <span class="text-red transaction-output" v-if="td.name === 'debit'">
                saída
              </span>
            </span>
            <span v-if="td.type === 'status'">
              <span class="text-greenblue">
                Aprovado
              </span>
              <!-- <span class="text-greenblue" v-if="td.name === 'approved'">
                Aprovado
              </span> -->
              <!-- <span class="text-red" v-if="td.name === 'reject'">
                Rejeitado
              </span> -->
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { intToRealBRL } from '@/utils/coin'
import { formatDate } from '@/utils/date'
import { defineComponent, ref, toRef } from 'vue'

export default defineComponent({
  name: 'TransactionTable',
  props: {
    loading: Boolean,
    columns: Array,
    rows: Array,
  },
  setup(props) {
    const rows = []
    const rowsTable = ref([])

    // const services = {
    //   fee: 'TAXA',
    //   internal_transfer: 'P2P',
    //   bank_transfer: 'TED',
    //   pix: 'PIX'
    // }

    function getColumnClass(type) {
      if (type === 'date' || type === 'text') {
        return 'text-left';
      } else if (type === 'situation' || type === 'status' || type === 'value' || type === 'receipt') {
        return 'text-center';
      } else {
        return '';
      }
    }

    toRef(props, 'rows').value.forEach(row => {
      let td = []
      toRef(props, 'columns').value.forEach(data => {
        td.push({
          type: data.type,
          name: row[data.field],
          redirectTo: data.to
        })
      })
      rows.push(td)
    })

    rowsTable.value = rows

    function formatDateToForm(dateStr) {
      const withHours = true
      return formatDate(dateStr, withHours)
    }

    function formatCentsToBRL(amount) {
      return intToRealBRL(amount)
    }

    return {
      getColumnClass,
      rowsTable,
      formatDateToForm,
      formatCentsToBRL,
      // services
    }
  },
  components: {

  },
})
</script>
