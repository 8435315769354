<template>
  <div class="content-body">
    <div class="header-border header-cancel">
      <div class="customer-header-status">
        <h1 class="text-title text-bold">Planos</h1>
      </div>
      <router-link to="/"><img src="../assets/img/sair.svg" alt=""></router-link>
    </div>
    <ul class="plans-list">
      <li class="thead text-bold text-plans">
        <div class="titem">Cesta</div>
        <div class="titem">Pacote na medida</div>
        <div class="titem">Pacote Cidadão</div>
        <div class="titem">Tarifa Base</div>
      </li>
      <li class="trow text-regular text-plans" v-for="(plan, index) in plans" :key="index">
        <div class="titem">{{ plan.description }}</div>
        <div class="titem">{{ plan.pack1 }}</div>
        <div class="titem">{{ plan.pack2 }}</div>
        <div class="titem">{{ plan.tax }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'CustomerExaminationPage',
  setup() {

    const plans = ref([
      {
        description: "MENSALIDADE",
        pack1: "R$ 39,90",
        pack2: "ISENTO",
        tax: "R$ 4,99"
      },
      {
        description: "PIX (CASH-IN)",
        pack1: "ISENTO",
        pack2: "ISENTO",
        tax: "ISENTO"
      },
      {
        description: "Transferência entre contas do RC Digital",
        pack1: "ISENTO",
        pack2: "ISENTO",
        tax: "R$ 0,00"
      },
      {
        description: "Pacote de Transações em geral (TED ou PIX)",
        pack1: "ISENTO",
        pack2: "ISENTO",
        tax: "R$ 0,00"
      },
      {
        description: "PIX - (CASH-OUT)",
        pack1: "20 transações",
        pack2: "2",
        tax: "R$ 0,69"
      },
      {
        description: "TED - 15:30H",
        pack1: "-",
        pack2: "0",
        tax: "R$ 1,99"
      },
      {
        description: "TED - APÓS 15:31H",
        pack1: "-",
        pack2: "0",
        tax: "R$ 2,49"
      },
      {
        description: "BOLETO - registro",
        pack1: "TARIFA NORMAL",
        pack2: "0",
        tax: "ISENTO"
      },
      {
        description: "BOLETO - Liquidação",
        pack1: "ISENTO",
        pack2: "TARIFA NORMAL",
        tax: "R$ 1,99"
      },
      {
        description: "CARTÃO DIGITAL",
        pack1: "ISENTO",
        pack2: "TARIFA NORMAL",
        tax: "R$ 1,99"
      },
      {
        description: "MENSALIDADE DO CARTÃO DIGITAL - ATÉ 10 CARTÕES DIGITAIS",
        pack1: "ISENTO",
        pack2: "ISENTO",
        tax: "R$ 9,99"
      },
      {
        description: "EMISSÃO DE CARTÃO FÍSICO",
        pack1: "ISENTO",
        pack2: "TARIFA NORMAL",
        tax: "R$ 35,00"
      },

      {
        description: "SAQUE - BANCO 24H",
        pack1: "TARIFA NORMAL",
        pack2: "TARIFA NORMAL",
        tax: "R$ 10,00"
      },
    ]);


    return {
      plans
    }
  }

})
</script>