<template>
  <div class="content-body">
    <div class="header-border header-cancel">
      <div class="customer-header-status">
        <h1 class="text-title text-bold">Cliente</h1>
        <div class="customer-status customer-status-default text-regular text-status-title"
          v-if="customerDetail.status === 'processing'">
          AGUARDANDO
        </div>
        <div class="customer-status customer-status-default text-regular text-status-title"
          v-if="customerDetail.status === 'pending'">PENDENTE</div>
        <div class="customer-status customer-status-active text-regular text-status-title"
          v-if="customerDetail.status === 'approved'">ATIVO</div>
        <div class="customer-status customer-status-default text-regular text-status-title"
          v-if="customerDetail.status === 'rejected'">INATIVO</div>
        <div class="customer-status customer-status-examination text-regular text-status-title"
          v-if="customerDetail.status === 'examination'">MALHA-FINA
        </div>
      </div>
      <router-link :to="`/clientes`"><img src="../assets/img/sair.svg" alt=""></router-link>
    </div>
    <LoadingComponent v-if="loading" />
    <PhysicalPersonDetail v-if="customerDetail.documentType === 'cpf'" :customerDetail="customerDetail" />
    <JuridicalPersonDetail v-if="customerDetail.documentType === 'cnpj'" :customerDetail="customerDetail" />
  </div>
</template>
<script>
import { requestGet } from '@/lib/api'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import JuridicalPersonDetail from '@/components/JuridicalPersonDetail.vue'
import PhysicalPersonDetail from '@/components/PhysicalPersonDetail.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'


export default defineComponent({
  name: 'CustomerDetailPage',
  components: {
    PhysicalPersonDetail,
    JuridicalPersonDetail,
    LoadingComponent
  },
  setup() {
    const customerDetail = ref({
      id: '',
      fullName: '',
      document: '',
      documentType: '',
      email: '',
      fiscalCountry: '',
      isPep: false,
      mainAddress: {
        city: '',
        complement: null,
        neighborhood: '',
        streetName: '',
        streetNumber: '',
        uf: '',
        zipcode: '',
      },
      monthlyIncome: 0,
      openingCompanyDate: '',
      patrimony: 0,
      phone: '',
      socialName: '',
      status: '',
      balance: 0,
      createdAt: ''
    })
    const route = useRoute()
    const loading = ref(true)

    onMounted(async () => {
      loading.value = true
      let result = await requestGet(`/account/detail/${route.params.id}`)
      customerDetail.value = result
      loading.value = false
    })

    function formatPhoneNumber(phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '')
      const formatted = cleaned.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
      return formatted
    }

    return {
      customerDetail,
      route,
      formatPhoneNumber,
      loading
    }
  }

})
</script>