<template>
  <div class="content-body">
    <div class="header-border header">
      <h1 class="text-title text-bold">Transações {{ userData.tenantOwnerFirstName }}</h1>
    </div>
    <div class="transaction-header">
      <div class="text-title text-bold">Saldo em conta: {{ formatAmount(userData.tenantBalance) }}</div>
      <div class="transaction-filter" v-if="false">
        <div class="transaction-filter-item">
          <label class="text-table-title text-semibold" for="initialDate">Data inicial:</label>
          <input class="text-table-title text-regular" type="date" id="initialDate">
        </div>
        <div class="transaction-filter-item">
          <label class="text-table-title text-semibold" for="finalDate">Data final:</label>
          <input class="text-table-title text-regular" type="date" id="finalDate">
        </div>
        <div class="transaction-filter-item">
          <button class="button-filter" type="submit" @click="getCustomers"><img class="img-search"
              src="../assets/svg/Search.svg" alt=""></button>
        </div>
        <div class="transaction-filter-item">
          <button class="button-filter"><img class="img-export" src="../assets/svg/export.svg" alt=""></button>
        </div>
      </div>
    </div>
    <LoadingComponent :columns="columns" v-if="loading" />
    <TransactionTable :columns="columns" :rows="rows" v-if="!loading" />
    <div v-if="!loading" class="customer-footer">
      <div class="" v-if="false">
        <p class="text-footer text-regular">Exibindo de {{ fromCount }} a {{ toShownCount }} de {{ totalItems }} itens</p>
      </div>
      <div class="" v-if="false">
        <button class="button-pag text-footer text-regular" @click="previousPage" v-if="previous">&lt; Anterior</button>
        <select class="text-footer text-semibold" name="" id="" @change="handlePage" v-model="pageSelect">
          <option :value="page" v-for="page in pages" :key="page">{{ page }}</option>
        </select>
        <button class="button-pag text-footer text-regular" @click="nextPage" v-if="next">Próximo &gt;</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import TransactionTable from '@/components/TransactionTable.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import { requestGet } from '@/lib/api'
import { intToRealBRL } from '@/utils/coin'

export default defineComponent({
  name: 'TransactionPage',
  components: {
    TransactionTable,
    LoadingComponent
  },
  setup() {
    const userData = ref({
      fullName: null,
      firstName: null,
      tenantOwnerFullName: null,
      tenantOwnerFirstName: null,
      tenantBalance: null
    })
    const param = ref(null)
    const pageSelect = ref(1)
    const loading = ref(true)
    const totalItems = ref(0);
    const currentPage = ref(0);
    const fromCount = ref(1);
    const toShownCount = ref(10);
    const next = ref(null);
    const previous = ref(null);

    const options = ref([])
    const selectedOption = ref('')

    const columns = [
      { field: 'createdAt', name: "Data", type: 'date' },
      { field: 'title', name: "Transação", type: 'text' },
      // { field: 'detailType', name: "Serviço", type: 'service' },
      { field: 'type', name: "Situação", type: 'situation' },
      { field: 'status', name: "Status", type: 'status' },
      { field: 'amount', name: "Valor", type: 'value' },
      { field: 'receipt', name: "Comprovante", type: 'receipt', to: '/cliente/{}/detalhe' },
    ]
    const rows = ref([])

    const pages = ref([])

    onMounted(async () => {
      let result = await requestGet('/tenant/detail')
      userData.value = result
      getTenantStatement()
    })

    async function getTenantStatement() {
      loading.value = true
      let result = await requestGet('/tenant/statements')
      console.log(result.data)
      rows.value = result.data
      loading.value = false
    }

    async function getCustomers() {
      loading.value = !loading.value
    }

    function nextPage() {
      currentPage.value = currentPage.value + 1
    }

    function previousPage() {
      currentPage.value = currentPage.value - 1
    }

    function handlePage() {
      currentPage.value = pageSelect.value
    }

    function formatAmount(value) {
      return intToRealBRL(value)
    }

    return {
      options,
      selectedOption,
      columns,
      rows,
      param,
      loading,
      totalItems,
      currentPage,
      fromCount,
      toShownCount,
      pages,
      getCustomers,
      nextPage,
      previousPage,
      next,
      previous,
      handlePage,
      pageSelect,
      userData,
      formatAmount
    }
  }

})
</script>