<template>
  <div class="layout">
    <SidebarComponent />
    <main>
      <div class="content">
        <router-view />
      </div>
    </main>
  </div>
</template>
<script>
import SidebarComponent from '@/components/SidebarComponent.vue'
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    SidebarComponent
  },
  name: 'DefaultLayout',
  setup() {
    const router = useRouter()
    onMounted(async () => {
      if (!localStorage.token) {
        router.replace('/login')
      }

    })
    return {}
  }
})
</script>
