<template>
  <div class="customer-form">
    <form action="">
      <fieldset class="customer-form-col-1">
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Nome</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            :value="customerDetail.fullName" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Telefone</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="54999999999"
            :value="formatPhoneNumber(customerDetail.phone)" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">E-mail</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="loremipsum@gmail.com"
            :value="customerDetail.email" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">CPF/CNPJ</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="00000000033"
            :value="customerDetail.document" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Data de Nascimento</label>
          <input class="customer-input text-customer-data text-regular text-gray" :value="customerDetail.birthDate"
            type="date" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Sexo</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            value="Masculino" type="text" v-if="customerDetail.gender === 'male'">
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            value="Feminino" type="text" v-if="customerDetail.gender === 'female'">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">RG</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="RG" type="text" readonly
            style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Órgão Emissor</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Órgão emissor" type="text"
            readonly style="pointer-events: none;">
        </div>
        <!-- <div class="data-group">
            <label class="text-customer-data text-bold text-gray" for="">Data de Emissão RG</label>
            <input class="customer-input text-customer-data text-regular text-gray" type="date">
          </div> -->
        <!-- <div class="data-group">
            <label class="text-customer-data text-bold text-gray" for="">Nome do Pai</label>
            <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum" type="text" readonly style="pointer-events: none;">
          </div> -->
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Nome da Mãe</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            :value="customerDetail.mothersName" type="text" readonly style="pointer-events: none;">
        </div>
      </fieldset>
      <fieldset class="customer-form-col-2">
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Estado Civil</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Estado Civil" type="text"
            readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">PPE</label>
          <input class="customer-input text-customer-data text-regular text-gray" value="SIM" v-if="customerDetail.isPep"
            type="text" readonly style="pointer-events: none;">
          <input class="customer-input text-customer-data text-regular text-gray" value="NÃO" v-if="!customerDetail.isPep"
            type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">CEP</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            :value="customerDetail.mainAddress.zipcode" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Endereço</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            :value="customerDetail.mainAddress.streetName" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Número</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            :value="customerDetail.mainAddress.streetNumber" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Complemento</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Complemento" type="text"
            readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Bairro</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            :value="customerDetail.mainAddress.neighborhood" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Cidade</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            :value="customerDetail.mainAddress.city" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="data-group">
          <label class="text-customer-data text-bold text-gray" for="">Estado</label>
          <input class="customer-input text-customer-data text-regular text-gray" placeholder="Lorem Ipsum"
            :value="customerDetail.mainAddress.uf" type="text" readonly style="pointer-events: none;">
        </div>
        <div class="form-button">
          <router-link :to="`/cliente/${route.params.id}/malha-fina`">
            <button class="text-customer-button text-semibold button-malha-fina hover-black" type="submit">
              Malha Fina
            </button>
          </router-link>
        </div>
        <!-- <div class="buttons-detail-page"> -->
        <!-- <button class="text-customer-button text-semibold button-approve hover-green" type="submit">Aprovar</button> -->
        <!-- <button class="text-customer-button text-semibold button-reject hover-red" type="submit">Rejeitar</button> -->
        <!-- </div> -->
      </fieldset>
    </form>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'


export default defineComponent({
  name: 'PhysicalPersonDetail',
  components: {},
  props: ['customerDetail'],
  setup() {

    const route = useRoute()

    function formatPhoneNumber(phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '')
      const formatted = cleaned.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
      return formatted
    }

    return {
      route,
      formatPhoneNumber,

    }
  }

})
</script>