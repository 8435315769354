const formatDate = function (date, withHours) {
  console.log(date);
  date = date.split(" ");
  let dateArr = date[0];
  dateArr = dateArr.split("-");
  let dateStr = dateArr[2] + "/" + dateArr[1] + "/" + dateArr[0];

  if (withHours === true) {
    let dateTime = date[1].split('.');
    dateStr += " " + dateTime[0];
  }

  return dateStr;
};

const formatDateMonthDay = function (dateStr) {
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const date = new Date(dateStr);
  const monthAbbr = months[date.getMonth()];
  const day = date.getDate();

  return `${monthAbbr} ${day}`;
};

//format string date dd/mm/yyyy to yyyy-mm-dd
const formatDateToDB = function (dateStr) {
  const date = dateStr.split("/");
  const dateDB = date[2] + "-" + date[1] + "-" + date[0];
  return dateDB;
};

export { formatDate, formatDateMonthDay, formatDateToDB };
