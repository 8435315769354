<template class="template">
  <header class="sidebar">
    <div class="menu-area">
      <div class="sidebar-logo">
        <!-- <router-link to="/"><img src="../assets/img/logo-adaga.png" alt="Logo Adaga" class="img-logo"></router-link> -->
        <router-link to="/"><img src="../assets/img/patria.png" alt="Logo Adaga" class="img-logo"></router-link>
      </div>
      <ul class="sidebar-list">
        <li>
          <div v-if="isCurrentGroup('dashboard')" class="sidebar-bar"></div>
          <div class="sidebar-item" :class="{
            'sidebar-item-active': isCurrentGroup('dashboard'),
          }">
            <router-link to="/dashboard">
              <DashboardIcon :isActive="isCurrentGroup('dashboard')" />
              <p class="text-sidebar text-regular">Dashboard</p>
            </router-link>
          </div>
        </li>
        <li>
          <div v-if="isCurrentGroup('transactions')" class="sidebar-bar"></div>
          <div class="sidebar-item" :class="{
            'sidebar-item-active': isCurrentGroup('transactions'),
          }">
            <router-link to="/movimentacoes">
              <TransactionsIcon :isActive="isCurrentGroup('transactions')" />
              <p class="text-sidebar text-regular">Movimentações</p>
            </router-link>
          </div>
        </li>
        <li>
          <div v-if="isCurrentGroup('customer')" class="sidebar-bar"></div>
          <div class="sidebar-item" :class="{
            'sidebar-item-active': isCurrentGroup('customer'),
          }">
            <router-link to="/clientes">
              <CustomersIcon :isActive="isCurrentGroup('customer')" />
              <p class="text-sidebar text-regular">Clientes</p>
            </router-link>
          </div>
        </li>
        <li>
          <div v-if="isCurrentGroup('plans')" class="sidebar-bar"></div>
          <div class="sidebar-item" :class="{
            'sidebar-item-active': isCurrentGroup('plans'),
          }">
            <router-link to="/planos">
              <PlansIcon :isActive="isCurrentGroup('plans')" />
              <p class="text-sidebar text-regular">Planos</p>
            </router-link>
          </div>
        </li>
        <li class="logout-item">
          <div class="sidebar-item">
            <div @click="handleLogout" style="cursor: pointer">
              <i class="fas fa-sign-out-alt fa-lg"></i>
              <p class="text-sidebar text-regular">Sair</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>

import { useRoute } from 'vue-router'
import DashboardIcon from "../assets/svg/DashboardIcon.vue";
import TransactionsIcon from "../assets/svg/TransactionsIcon.vue";
import CustomersIcon from "../assets/svg/CustomersIcon.vue";
import PlansIcon from "../assets/svg/PlansIcon.vue";
import { logout } from '@/lib/api'


export default {
  name: 'SidebarComponent',
  components: { DashboardIcon, TransactionsIcon, CustomersIcon, PlansIcon },
  setup() {
    const route = useRoute()
    function isCurrentGroup(group) {
      return group === route.meta.group
    }

    function handleLogout() {
      logout()
    }

    return {
      isCurrentGroup,
      handleLogout
    }
  }
}
</script>
