<template>
  <router-view />
</template>
<style lang="scss">
@import './styles/Vars';
@import './styles/Reset';
@import './styles/Styles';
@import './styles/Layout.scss';
@import './styles/Button';
@import './styles/CustomerPage';
@import './styles/CustomerDetailPage';
@import './styles/CustomerExaminationPage';
@import './styles/CustomerTransactionPage';
@import './styles/DashboardPage';
@import './styles/PlansPage';
@import 'styles/Text.scss';
@import 'styles/LoginPage.scss';
@import 'styles/Table.scss';
@import 'styles/TableTransaction.scss';
@import 'styles/TableCustomer.scss';
@import 'styles/fonts/style';
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>
<script>
export default {
  name: 'App'
}
</script>
