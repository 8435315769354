<template>
  <div class="content-body">
    <LoadingComponent :columns="columns" v-if="loading" />
    <div class="header-border header-cancel" v-if="!loading">
      <div class="customer-header-status">
        <h1 class="text-title text-bold">Sócios da Empresa</h1>
        <div class="customer-status customer-status-default text-regular text-status-title"
          v-if="customerDetail.status === 'processing'">
          AGUARDANDO
        </div>
        <div class="customer-status customer-status-default text-regular text-status-title"
          v-if="customerDetail.status === 'pending'">PENDENTE</div>
        <div class="customer-status customer-status-active text-regular text-status-title"
          v-if="customerDetail.status === 'approved'">ATIVO</div>
        <div class="customer-status customer-status-default text-regular text-status-title"
          v-if="customerDetail.status === 'reject'">INATIVO</div>
        <div class="customer-status customer-status-examination text-regular text-status-title"
          v-if="customerDetail.status === 'examination'">MALHA-FINA
        </div>
      </div>
      <button @click="showModal = !showModal" class="button-add text-semibold">Adicionar Sócio</button>
      <router-link :to="`/cliente/${route.params.id}/detalhe`"><img src="../assets/img/sair.svg" alt=""></router-link>
    </div>
    <CustomerTable :columns="columns" :rows="rows" v-if="!loading" />
    <div class="overlay" v-if="showModal">
      <div class="modal" :v-if="false">
        <button class="close-btn" @click="closeModal"><img src="../assets/svg/close.svg" alt=""></button>
        <div class="partner-form">
          <form action="">
            <fieldset class="partner-form-col-1">
              <div class="modal-data-group">
                <label class="text-customer-data text-bold text-gray" for="">CPF</label>
                <div>
                  <input v-model="newPartner.document" v-mask="'###.###.###-##'"
                    class="partner-input text-customer-data text-regular text-gray" placeholder="Digite o CPF"
                    type="text">
                  <p v-if="errors.document" class="text-input-error text-red modal-input-error">
                    {{ errors.document }}
                  </p>
                </div>
              </div>
              <div class="modal-data-group">
                <label class="text-customer-data text-bold text-gray" for="">Telefone</label>
                <div>
                  <input v-model="newPartner.phone" v-mask="'(##) #####-####'"
                    class="partner-input text-customer-data text-regular text-gray" placeholder="Digite o celular"
                    type="text">
                  <p v-if="errors.phone" class="text-input-error text-red modal-input-error">
                    {{ errors.phone }}
                  </p>
                </div>
              </div>
              <div class="modal-data-group">
                <label class="text-customer-data text-bold text-gray" for="">Tipo</label>
                <div>
                  <select v-model="newPartner.representativeType" placeholder="Selecione o tipo"
                    class="partner-select-input text-customer-data text-bold text-gray" name="">
                    <option value="administrative_partner">Sócio Administrador</option>
                    <option value="owner">Proprietário</option>
                    <option value="partner">Sócio</option>
                  </select>
                  <p v-if="errors.representativeType" class="text-input-error text-red modal-input-error">
                    {{ errors.representativeType }} </p>
                </div>
              </div>
              <div class="modal-button">
                <button @click="handleAddPartner" :disabled="addingRepresentative"
                  class="text-customer-button text-semibold button-malha-fina hover-black" type="submit">
                  <span v-if="!addingRepresentative">Adicionar</span>
                  <span v-if="addingRepresentative" class="loading-icon"></span>
                </button>
              </div>
              <p v-if="status == 'success'" class="text-greenblue text-bold text-title button-sucess-msg">Sócio
                adicionado com sucesso!</p>
              <p v-if="status == 'error'" class="text-red text-bold text-title button-sucess-msg">{{ error }}</p>
            </fieldset>
          </form>

        </div>

      </div>
    </div>
    <div v-if="!loading" class="customer-footer">
      <div class="">
        <p class="text-footer text-regular">Exibindo de {{ fromCount }} a {{ toShownCount }} de {{ totalItems }}
          itens</p>
      </div>
      <button v-if="customerDetail.status === 'pending'" @click="verifyAccount" class="button-add text-semibold">Verificar
        conta</button>
      <div class="">
        <button class="button-pag text-footer text-regular" @click="goToPreviousPage"
          :disabled="previousPage == null">&lt;
          Anterior</button>
        <select class="text-footer text-semibold" name="" id="" @change="handlePage" v-model="currentPage">
          <option :value="page" v-for="page in pages" :key="page">{{ page }}</option>
        </select>
        <button class="button-pag text-footer text-regular" @click="goToNextPage" :disabled="nextPage == null">Próximo
          &gt;</button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import CustomerTable from '@/components/CustomerTable.vue'
import { useRoute, useRouter } from 'vue-router'
import { validateCPF, validatePhone } from '@/utils/validator.js'
import { requestPostPublic, requestGet } from '@/lib/api'
import LoadingComponent from '@/components/LoadingComponent.vue'
import { AlertUtils } from '@/utils/alert.js'



export default defineComponent({
  name: 'CustomerPartnerPage',
  components: {
    CustomerTable,
    LoadingComponent
  },
  setup() {

    onMounted(async () => {
      loading.value = true
      try {
        await Promise.all([
          getCustomerDetail(),
          getRepresentativeList(1)
        ])
      } catch (error) {
        console.error(error)
      } finally {
        loading.value = false
      }
    })

    const columns = [
      { field: 'companyId', name: "ID", type: 'uuid' },
      { field: 'type', name: "TIPO", type: 'representativeType' },
      //{ field: 'phone', name: "TELEFONE", type: 'phone' },
      { field: 'document', name: "DOCUMENTO", type: 'text' },
      { field: 'status', name: "Status", type: 'status' },
    ]

    const loading = ref(false)
    const addingRepresentative = ref(false)
    const errors = ref({})
    const status = ref('waiting')
    const error = ref('')
    const currentPage = ref(1);
    const nextPage = ref(null)
    const previousPage = ref(null)
    const fromCount = ref(1);
    const toShownCount = ref(10);
    const totalItems = ref(0);
    const customerDetail = ref({})
    const pages = ref([])
    const pageSelect = ref(1)
    const rows = reactive([])

    const getCustomerDetail = async () => {
      try {
        const response = await requestGet(`/account/detail/${route.params.id}`)
        console.log(response)
        customerDetail.value = response
      } catch (e) {
        console.log(e)
      }
    }


    const validateFields = () => {
      errors.value = {}

      if (!validateCPF(newPartner.document)) errors.value.document = 'Digite um CPF válido';
      if (!validatePhone(newPartner.phone)) errors.value.phone = 'Digite um Telefone válido';
      if (!newPartner.representativeType) errors.value.representativeType = 'Digite  um tipo válido';
    }

    const newPartner = reactive({
      id: '',
      representativeType: '',
      //phone: '',
      document: '',
      status: ''
    })

    const route = useRoute()
    const router = useRouter()

    const showModal = ref(false)

    //close model reset erros and fields
    const closeModal = () => {
      if (status.value == 'success') {
        window.location.reload()
      }
      else {
        showModal.value = false
        errors.value = {}
        status.value = 'waiting'
        newPartner.id = ''
        newPartner.representativeType = ''
        newPartner.phone = ''
        newPartner.document = ''
        newPartner.status = ''
      }
    }

    const getRepresentativeList = async (pageIndex) => {
      loading.value = true
      rows.splice(0)
      try {
        const response = await requestGet(`/account/${route.params.id}/representatives`, { page: pageIndex })
        console.log(response)
        if (response.nextPage != null) nextPage.value = response.currentPage + 1
        else nextPage.value = null
        if (response.previousPage != null) previousPage.value = response.currentPage - 1
        else previousPage.value = null
        totalItems.value = response.totalReg
        if (response.currentPage === 1) {
          if (totalItems.value < 10) {
            toShownCount.value = totalItems.value
          } else {
            toShownCount.value = 10;
          }
        }
        if (response.currentPage > 1) {
          fromCount.value = (response.currentPage - 1) * 10
          toShownCount.value = fromCount.value + response.data.length
        }
        currentPage.value = response.currentPage

        if (totalItems.value <= 10) {
          pages.value = 1
        } else {
          const pageAmount = totalItems.value / 10
          pages.value = pageAmount
        }




        if (Array.isArray(response.data)) {
          response.data.forEach(representative => {
            //switch representive type

            rows.push(representative)
          })
        } else {
          console.error('Response.data is not an array:', response.data)
        }
      } catch (e) {
        console.log(e)
      }
      loading.value = false
    }

    const goToNextPage = () => {
      if (nextPage.value) {
        getRepresentativeList(nextPage.value)
      }
    }

    const goToPreviousPage = () => {
      if (previousPage.value) {
        getRepresentativeList(previousPage.value)
      }
    }

    const handlePage = () => {
      getRepresentativeList(pageSelect.value)
    }

    const handleAddPartner = async () => {
      validateFields()
      if (Object.keys(errors.value).length) return
      errors.value = {}
      addingRepresentative.value = true

      const dataToSend = { ...newPartner }
      dataToSend.document = dataToSend.document.replace(/\D/g, '')
      dataToSend.phone = dataToSend.phone.replace(/\D/g, '')

      try {
        const response = await requestPostPublic(`ws/account/${route.params.id}/add-representative`, dataToSend)
        console.log(response)
        newPartner.id = ''
        newPartner.representativeType = ''
        newPartner.phone = ''
        newPartner.document = ''
        newPartner.status = ''
        status.value = 'success'
      } catch (e) {
        if (e.response.data.message == 'Representative already exists') error.value = 'Representante já existe'
        else error.value = 'Erro ao adicionar sócio'
        status.value = 'error'
      }
      addingRepresentative.value = false
    }

    const verifyAccount = async () => {
      //verify if all representives are approved else show popup with message
      if (rows.length == 0) {
        AlertUtils.error('Não é possível verificar a conta, pois não existem sócios')
        return
      }
      let allApproved = true
      rows.forEach(row => {
        if (row.status != "accepted") allApproved = false
      })
      if (!allApproved) {
        AlertUtils.error('Não é possível verificar a conta, pois existem sócios pendentes')
        return
      }
      //goto verify juridical customer page
      router.push(`/cliente/${route.params.id}/verificar-pj`)
    }

    return {
      columns,
      rows,
      route,
      showModal,
      handleAddPartner,
      newPartner,
      errors,
      loading,
      closeModal,
      status,
      error,
      getRepresentativeList,
      goToNextPage,
      goToPreviousPage,
      fromCount,
      toShownCount,
      totalItems,
      nextPage,
      previousPage,
      customerDetail,
      verifyAccount,
      currentPage,
      pages,
      pageSelect,
      handlePage,
      addingRepresentative
    }
  }

})
</script>