<template>
  <div class="content-body">
    <div class="header-border header-cancel">
      <div class="customer-header-status">
        <h1 class="text-title text-bold">Cliente - Malha Fina</h1>
      </div>
      <router-link :to="`/cliente/${route.params.id}/detalhe`"><img src="../assets/img/sair.svg" alt=""></router-link>
    </div>
    <div class="customer-data-container">
      <div class="text-subtitle text-bold">Dados na Receita Federal</div>
      <div class="customer-data-content" v-if="false">
        <div class="customer-data-col-1">
          <div class="customer-data">
            <div class="data-description text-customer-data text-bold text-gray">Nome/Razão social</div>
            <input class="customer-input text-customer-data text-regular text-gray" placeholder="Nome/Razão social"
              type="text">
          </div>
          <div class="customer-data">
            <div class="data-description text-customer-data text-bold text-gray">Data de Nascimento</div>
            <input class="customer-input text-customer-data text-regular text-gray" type="date">
          </div>
        </div>
        <div class="customer-data-col-2">
          <div class="customer-data">
            <div class="data-description text-customer-data text-bold text-gray">Status do CPF</div>
            <input class="customer-input text-customer-data text-regular text-gray" placeholder="Status do CPF"
              type="text">
          </div>
          <div class="customer-data">
            <div class="data-description text-customer-data text-bold text-gray">Linhas Restritivas</div>
            <input class="customer-input text-customer-data text-regular text-gray" placeholder="NÃO" type="text">
          </div>
        </div>
      </div>
      <LoadingComponent v-if="loading" />
      <div v-if="!loading" class="customer-docs">
        <div class="customer-doc" v-if="customerDocuments.frontDocumentTemporalyLink"><img
            :src="customerDocuments.frontDocumentTemporalyLink" alt=""></div>
        <div class="customer-doc" v-if="customerDocuments.backDocumentTemporalyLink"><img
            :src="customerDocuments.backDocumentTemporalyLink" alt=""></div>
        <div class="customer-doc" v-if="customerDocuments.selfieTemporalyLink"><img
            :src="customerDocuments.selfieTemporalyLink" alt=""></div>
        <div class="customer-doc" v-if="customerDocuments.proofOfAddressTemporalyLink"><img
            :src="customerDocuments.proofOfAddressTemporalyLink" alt=""></div>
        <a v-if="customerDocuments.socialContractTemporalyLink" :href="customerDocuments.socialContractTemporalyLink"
          target="_blank">Ver Contrato Social</a>
        <a v-if="customerDocuments.proofOfIncomeTemporalyLink" :href="customerDocuments.proofOfIncomeTemporalyLink"
          target="_blank">Ver DRE</a>
      </div>
    </div>
  </div>
</template>
<script>
import { requestGet } from '@/lib/api'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import LoadingComponent from '@/components/LoadingComponent.vue'


export default defineComponent({
  name: 'CustomerExaminationPage',
  components: {
    LoadingComponent
  },
  setup() {
    const route = useRoute()
    const loading = ref(true)
    const customerDocuments = ref({
      selfieTemporalyLink: null,
      frontDocumentTemporalyLink: null,
      backDocumentTemporalyLink: null,
      socialContractTemporalyLink: null,
      proofOfIncomeTemporalyLink: null
    })
    onMounted(async () => {
      const result = await requestGet(`/account/documents/${route.params.id}`)
      customerDocuments.value = result
      loading.value = false
    })
    return {
      customerDocuments,
      route,
      loading
    }
  }

})
</script>