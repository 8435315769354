<template>
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M8.75381 9.53174H6.22194C5.62414 9.53174 5.13184 10.024 5.13184 10.6218V15.5361H8.75381V9.53174Z"
      stroke-width="1.63" stroke-linecap="round" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M11.2857 5.1626H9.8351C9.23729 5.1626 8.745 5.6549 8.745 6.2527V15.5274H12.367V6.2527C12.367 5.64611 11.8922 5.1626 11.2857 5.1626Z"
      stroke-width="1.63" stroke-linecap="round" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M14.9077 11.1582H12.3759V15.5274H15.9978V12.2571C15.9891 11.6505 15.4967 11.1582 14.9077 11.1582Z"
      stroke-width="1.63" stroke-linecap="round" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M7.69011 20.1912H13.4308C18.2132 20.1912 20.1209 18.2747 20.1209 13.4747V7.7165C20.1209 2.9165 18.2044 1 13.4308 1H7.69011C2.90769 1 1 2.9165 1 7.7165V13.4747C1 18.2747 2.91648 20.1912 7.69011 20.1912Z"
      stroke-width="1.63" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TransactionsIcon',
  props: {
    isActive: Boolean,
  },
})
</script>


