<template>
  <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M7.34301 8.61905C7.23625 8.60554 7.1295 8.60554 7.02274 8.61905C4.735 8.5515 2.90479 6.87639 2.90479 4.80952C2.90479 2.74266 4.81126 1 7.19051 1C9.56976 1 11.4762 2.71564 11.4762 4.80952C11.461 6.87639 9.63076 8.5515 7.34301 8.61905Z"
      stroke-width="1.63" stroke-linecap="round" stroke-linejoin="round" />
    <path :stroke="isActive ? 'var(--orange)' : 'white'"
      d="M2.6472 13.7901C0.450932 15.4019 0.450932 18.0308 2.6472 19.627C5.14493 21.4579 9.23603 21.4579 11.7337 19.627C13.93 18.0152 13.93 15.3863 11.7337 13.7901C9.25038 11.9749 5.14493 11.9749 2.6472 13.7901Z"
      stroke-width="1.63" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomersIcon',
  props: {
    isActive: Boolean,
  },
})
</script>


