import { createRouter, createWebHashHistory } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout";
import DashboardPage from "@/views/DashboardPage";
import CustomerPage from "@/views/CustomerPage";
import CustomerDetailPage from "@/views/CustomerDetailPage";
import CustomerExaminationPage from "@/views/CustomerExaminationPage";
import CustomerTransactionPage from "@/views/CustomerTransactionPage";
import CreateJuridicalCustomer from "@/views/CreateJuridicalCustomer";
import VerifyJuridicalCustomerPage from "@/views/VerifyJuridicalCustomerPage";
import CustomerPartnerPage from "@/views/CustomerPartnerPage";
import TransactionPage from "@/views/TransactionPage";
import PlansPage from "@/views/PlansPage";
import LoginPage from "@/views/LoginPage";

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: DefaultLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardPage,
        meta: {
          group: "dashboard",
        },
      },
      {
        path: "/movimentacoes",
        name: "Movimentações",
        component: TransactionPage,
        meta: {
          group: "transactions",
        },
      },
      {
        path: "/clientes",
        name: "Meus Clientes",
        component: CustomerPage,
        meta: {
          group: "customer",
        },
      },
      {
        path: "/cliente/:id/detalhe",
        name: "Detalhes do cliente",
        component: CustomerDetailPage,
        meta: {
          group: "customer",
        },
      },
      {
        path: "/cliente/:id/movimentacoes",
        name: "Movimentações do cliente",
        component: CustomerTransactionPage,
        meta: {
          group: "customer",
        },
      },
      {
        path: "/cliente/:id/malha-fina",
        component: CustomerExaminationPage,
        meta: {
          group: "customer",
        },
      },
      {
        path: "/cliente/:id/socios",
        component: CustomerPartnerPage,
        meta: {
          group: "customer",
        },
      },
      {
        path: "/cliente/:id/verificar-pj",
        component: VerifyJuridicalCustomerPage,
        meta: {
          group: "customer",
        },
      },
      {
        path: "/cliente/criar-pj",
        component: CreateJuridicalCustomer,
        meta: {
          group: "customer",
        },
      },
      {
        path: "/planos",
        name: "Planos",
        component: PlansPage,
        meta: {
          group: "plans",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

export default router;
